import React from 'react';

import { providers } from '../../../IntegrationProvider';
import AttributeComponent from './AttributeComponent';
import NoopComponent from './NoopComponent';
import Office365 from './Office365';
import Email from './Email';
import SFTP from './SFTP';
class HashMap {
  constructor() {
    this.data = {};
  }
  put(key, value) {
    this.data[key] = value;
  }
  getArray() {
    return Object.entries(this.data);
  }
}

export default function IntegrationComponentFactory({
  provider,
  groupIntegration,
  integration,
  groupId
}) {
  let attrMap = new HashMap();

  switch (provider) {
    case providers.EMAIL:
      return <Email provider={provider} integration={integration} groupId={groupId} />;
    case providers.SFTP:
      return <SFTP groupId={groupId} />;
    case providers.OFFICEARCHIVE:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return <Office365 groupId={groupId} provider={provider} integration={integration}/>
    case providers.ERADO:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Erado"
        />
      );
    case providers.PATRINA:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Patrina"
        />
      );
    case providers.PROOFPOINT:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Proofpoint"
        />
      );
    case providers.MESSAGEWATCHER:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Message watcher"
        />
      );
    case providers.MIMECAST:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Minecast"
        />
      );
    case providers.MIRRORWEB:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return(
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Mirrorweb"
        />
      )
    case providers.ONESECURE:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Onesecure"
        />
      );
    case providers.VAULTAMERICA:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Vault America"
        />
      );
    case providers.INTRADYN:
      attrMap.put('email', 'BUSINESS EMAIL ADDRESS');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Intradyn"
        />
      );
    case providers.GLOBALRELAY:
      attrMap.put('token', 'SMTP Destination Address*');
      attrMap.put('server', 'SMTP Server*');
      attrMap.put('port', 'SMTP Port*');
      attrMap.put('username', 'SMTP Username*');
      attrMap.put('password', 'SMTP Password*');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Global Relay"
        />
      );

    case providers.MICROFOCUS:
      break;
    case providers.MICROFOCUS:
      break;
    case providers.RIAINABOX:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS');
      return(<AttributeComponent
      provider={provider}
      integration={integration}
      attributes={attrMap}
      allowContactEdits={false}
      label="RIA in a Box"
    />)
    case providers.RIACOMPLIANCETECHNOLOGY:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS');
      return(<AttributeComponent
      provider={provider}
      integration={integration}
      attributes={attrMap}
      allowContactEdits={false}
      label="RIA Compliance Technology"
      />)
    case providers.SLACK:
      attrMap.put('token', 'WEBHOOK');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Slack"
        />
      );
    case providers.SMARSH:
      attrMap.put('email', 'ARCHIVE EMAIL ADDRESS/TOKEN');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          label="Smarch"
        />
      );
    default:
      return (
        <NoopComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
        />
      );
  }
  return null;
}
