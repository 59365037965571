import React from "react";
import {  ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import getQuery from "../getQuery";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import AppColor from "../../Constants/Color";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
import Modal from 'modal-react-native-web';
import { useToast } from "native-base";
import { useNavigation } from "@react-navigation/native";

const AccountInformation = ({ tenant, reseller, sysadmin }) => {
    const navigation = useNavigation();
    const skipAffiliates = !reseller && !sysadmin;

    const { data: groupsData } = getQuery({
        action: 'get_affiliates',
        reseller: reseller
    }, {
        skip: skipAffiliates
    })

    const Toast = useToast();

    const Countries = getQuery({ action: 'get_countries' });
    const Timezones = getQuery({ action: 'get_timezones' });

    const Tenant = tenant.data.tenant;

    const countryData = Countries.data?.countries
        ? Countries.data.countries.map(e => ({ label: e.label, value: e.isoCode }))
        : [];

    const timezoneData = Timezones.data?.timezones
        ? Timezones.data.timezones.map(e => ({ label: e.label, value: e.id }))
        : [];

    const ACCOUNT_STATES = [
        {
            value: 1,
            label: 'Active'
        },
        {
            value: 2,
            label: 'Inactive'
        },
        {
            value: 3,
            label: 'Review'
        },
        {
            value: 4,
            label: 'Trial'
        },
        {
            value: 5,
            label: 'Default'
        },
        {
            value: 90,
            label: 'Comp'
        },
        {
            value: 9,
            label: 'Deleted'
        }
    ]

    const [data, setData] = React.useState({
        name: Tenant.name,
        address1: Tenant.address1,
        address2: Tenant.address2,
        city: Tenant.city,
        state: Tenant.state,
        zip: Tenant.zip,
        contact: Tenant.contact,
        email: Tenant.email,
        isoCountry: Tenant.isoCountry,
        timezone: Tenant.timezone,
        uniqueId: Tenant.uniqueId,
        state: Tenant.state,
        status: Tenant.status,
        cdr: Tenant.cdr,
        affiliate: String(Tenant.affiliate),
        provider: Tenant.provider

    })


    const [deleteAccountModal, setDeleteAccountModal] = React.useState(false);

    React.useEffect(()=> {
        setData({
            name: Tenant.name,
            address1: Tenant.address1,
            address2: Tenant.address2,
            city: Tenant.city,
            state: Tenant.state,
            zip: Tenant.zip,
            contact: Tenant.contact,
            email: Tenant.email,
            isoCountry: Tenant.isoCountry,
            timezone: Tenant.timezone,
            uniqueId: Tenant.uniqueId,
            state: Tenant.state,
            status: Tenant.status,
            cdr: Tenant.cdr,
            affiliate: String(Tenant.affiliate),
            provider: Tenant.provider
        })
    },[Tenant])

    if((reseller && !groupsData) || (sysadmin && !groupsData)) return null;

    const handleSave = async () => {
        const body = {
            action: 'update_tenant',
            tenant_id: Tenant.id,
            ...data
        }
        if(reseller){
            body.reseller = reseller;
        }

        const result = await RequestMaker(body);
        if(result.data.success){
            tenant.refetch();
        }
    }

    const handleDeleteAccount = async () => {
        try{
        const body ={
            id: Tenant.id,
            action: "delete_tenant"
        }

        const result = await RequestMaker(body);

        if(result.data.success){
            Toast.show({
                status: 'success',
                title: 'Account successfully deleted',
            })
            navigation.goBack();
        } else if(result.data.errorMessage){
            Toast.show({
                status: 'error',
                title: result.data.errorMessage,
            })
        }
        } catch(e){
            Toast.show({
                status: 'error',
                title: 'An error occurred while deleting the account',
            })
        }


    }

    const handleChangeProvider = async (e) => {
        if(!Tenant.tw_number){
            Toast.show({
                status:'error',
                title: 'Tenant does not have a MRC number'
            })
            return;
        }
        try{
        const body = {
            action: "set_provider",
            tw_number: Tenant.tw_number,
            provider: e,
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                status:'success',
                title: "Provider changed successfully"
            })
            tenant.refetch()
        } else if(result.data.errorMessage){
            Toast.show({
                status:'error',
                title: result.data.errorMessage
            })
        }
        } catch(err){
            Toast.show({
                status:'error',
                title: err.message
            })
        }
    
    }

    return (
        <View style={{flex:1}}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>Please provide the basic account information.</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 20, marginTop: 20 }}>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, name: e})} value={data.name} title="Customer/Business Name*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, address1: e})} value={data.address1} title="Address Line 1*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, address2: e})} value={data.address2} title="Address Line 2" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, city: e})} value={data.city} title="City*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, state: e})} value={data.state} title="State/Region*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, zip: e})} value={data.zip} title="Zip/Postal Code*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, contact: e})} value={data.contact} title="Contact Person*" />
                </View>
                <View style={{ width: '40%' }}>
                    <CustomInput onChangeText={e => setData({...data, email: e})} value={data.email} title="Contact Person Email*" />
                </View>
            </View>

            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>Geographic information for this account.</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Country</Text>
            <SimpleDropdown
                value={data.isoCountry}
                data={countryData}
                onChange={e => setData({...data, isoCountry: e})}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Timezone</Text>
            <SimpleDropdown
                value={data.timezone}
                data={timezoneData}
                onChange={e => setData({...data, timezone: e})}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>Unique ID for this account (Can be used for your own internal tracking and API calls).</Text>
            <CustomInput onChangeText={e => setData({...data, uniqueId: e})} value={data.uniqueId} title="Unique ID (256 Character Max)" />

            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 20 }}>The state of the account.</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Account State</Text>
            <SimpleDropdown onChange={e => setData({...data, status: e})} value={data.status} data={ACCOUNT_STATES} />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>CRD Number</Text>
            <CustomInput onChangeText={e => setData({...data, cdr: e})} value={data.cdr}/>

{ sysadmin &&     <>      <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Carrier</Text>
            <SimpleDropdown value={data.provider} data={[{label: 'Bandwidth', value: 'bandwidth'}, {label: 'Twilio', value: 'twilio'}]} onChange={e=> handleChangeProvider(e)} />
</>
}
            {!skipAffiliates &&
            <>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Group</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>If this account belongs to a group, please select the group that the account belongs to here. Leave set to None for no group affiliation.</Text>
                <SimpleDropdown
                    style={{marginTop:10}}
                    value={String(data.affiliate)}
                    data={groupsData?.affiliates.map(e => ({label: e.name, value: String(e.id)})) }
                    onChange={e => setData({...data, affiliate: String(e)})}
                />

            </>
            }

            <CustomButton onPress={() => setDeleteAccountModal(true)} textColor={AppColor.danger} textHoverColor={'white'} backgroundHoverColor={AppColor.danger} title={'Delete Account'} outline width={'30%'} style={{alignSelf:'flex-start', borderColor: AppColor.danger,color:AppColor.danger,marginTop:20}}/>
            
            <CustomButton onPress={handleSave} title="Save" width={'20%'} style={{alignSelf:'flex-end',marginTop:10}}/>
            
            {deleteAccountModal &&
            <Modal
            visible={deleteAccountModal}
            transparent={true}
            >
                    <RemovePhoneModal
                        handleRemove={handleDeleteAccount}
                        title={'Permanently Delete Account'}
                        message={`You are about to permanently delete the account ${data.name} and all of its data, are you sure you want to continue? This cannot be undone.`}
                        setRemoveModal={setDeleteAccountModal}
                    />
                    </Modal>
                }

        </ScrollView>


        </View>
    )
}

export default AccountInformation;