import React from "react";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";
import { Switch, Text } from "react-native";
import Modal from 'modal-react-native-web';
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
const LoginEmails = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();
    const [disableModal, setDisableModal] = React.useState(false);

    const handleUpdate = async () => {
            setDisableModal(false);
         updateAffiliate({loginEmailDisabled: !affiliate.loginEmailDisabled }) 
    }

    return(
    <>

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Disable Automated Emails for Additional Account Logins        
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.loginEmailDisabled} onValueChange={() => setDisableModal(true)} />
    
        {
          disableModal &&
          <Modal visible={disableModal} transparent={true}>
            <RemovePhoneModal
              message={ affiliate.loginEmailDisabled ? 'Are you sure you want to enable automated emails for additional account logins?' : 'Are you sure you want to disable automated emails for additional account logins?'}
              title={ affiliate.loginEmailDisabled ? 'Enable Additional Account Login Emails': 'Disable Additional Account Login Emails'}
              continueLabel={'Continue'}
              handleRemove={handleUpdate}
              setRemoveModal={setDisableModal}
            />
          </Modal>
        }
    </>
    )
}

export default LoginEmails;