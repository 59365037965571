import React from "react";
import { Text, View } from "react-native";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import getQuery from "../getQuery";
import Accordion from "../../Components/Accordion";
import AppFonts from "../../Constants/Fonts";
import AccountInformation from "./AccountInformation";
import Loader from "../../Components/Loader/index.web";
import AddOns from "./AddOns";
import { Icon } from "react-native-elements";
import PhoneNumbers from "./PhoneNumbers";
import VCards from "./VCards";
import Logins from "./Logins";
import PhoneNumberConsent from "./PhoneNumberConsent";
import AccountActivity from "./AccountActivity";
import ExportContacts from "./ExportContacts";
import RunReport from "./RunReport";
import Disclosure from "./Disclosure";
import Media from "./Media";
import Integrations from "./Integrations";
import APIAccess from "./APIAccess";
import ArchiveAudit from "../Settings/ArchiveAudit";
import ArchiveLayout from "../Settings/ArchiveLayout";
import ConnectArchiver from "../Settings/ConnectArchiver";
import DeleteConsent from "./DeleteConsent";
import SystemAdminTools from "./SystemAdminTools";


const AccountDetails = ({ route, navigation }) => {
    const { tenant, reseller, sysadmin } = route.params;
    const Tenant = getQuery({ action: 'get_tenant', tenant: tenant });
    const [screen , setScreen] = React.useState('Account Information');
    if(!Tenant.data) return null;
    if(Tenant.isLoading) return <Loader backgroundColor="transparent"/>;

    let accountSettings = [
        {
            title: 'Account Information',
            onPress: () => setScreen('Account Information')
        },
        {
            title: 'Phone Numbers',
            onPress: () => setScreen('Phone Numbers')
        },
        {
            title: 'vCards',
            onPress: () => setScreen('vCards')
        },
        {
            title: 'Logins',
            onPress: () => setScreen('Logins')
        },
        {
            title: 'Add-Ons',
            onPress: () => setScreen('Add-Ons')
        },
        {
            title: 'Phone Number Consent',
            onPress: () => setScreen('Phone Number Consent')
        },
        {
            title: 'Reporting',
            subPages: [
                {
                    title: 'Account Activity',
                    onPress: () => setScreen('Account Activity')

                },
                {
                    title: 'Export Contacts',
                    onPress: () => setScreen('Export Contacts')
                },
                {
                    title: 'Run Report',
                    onPress: () => setScreen('Run Report')
                }
            ]
        },
        {
            title: 'Disclosure (View-Only)',
            onPress: () => setScreen('Disclosure (View-Only)')
        },
        {
            title: 'Media (View-Only)',
            onPress: () => setScreen('Media (View-Only)')
        },
        {
            title: 'Integrations',
            onPress: () => setScreen('Integrations')
        },
        {
            title: 'API Access',
            onPress: () => setScreen('API Access')
        },
        {
            title: 'Archive',
            subPages: [
                {
                    title: 'Archive Layout',
                    onPress: () => setScreen('Archive Layout')
                },
                {
                    title: 'Archive Provider',
                    onPress: () => setScreen('Archive Provider')
                },
                {
                    title: 'Archive Audit',
                    onPress: () => setScreen('Archive Audit')
                }
            ]

        },
    ]

    if(sysadmin){
        accountSettings = [
            ...accountSettings,
            {
                title: 'System Administrator',
                subPages: [
                    {
                        title: 'Delete Consent',
                        onPress: () => setScreen('Delete Consent')
                    },
                    {
                        title: 'System Admin Tools',
                        onPress: () => setScreen('System Admin Tools')
                    }
                ]
            }, 
        ]
    }

    const KEY_COMPONENT = {
        'Account Information': <AccountInformation sysadmin={sysadmin} reseller={reseller} tenant={Tenant}/>,
        'Add-Ons': <AddOns sysadmin={sysadmin} tenant={Tenant}/>,
        'Phone Numbers': <PhoneNumbers tenant={Tenant}/>,
        'vCards': <VCards tenant={Tenant}/>,
        'Logins': <Logins tenant={Tenant}/>,
        'Phone Number Consent': <PhoneNumberConsent tenant={Tenant} />,
        'Account Activity': <AccountActivity tenant={Tenant}/>,
        'Export Contacts': <ExportContacts tenant={Tenant}/>,
        'Run Report': <RunReport tenant={Tenant}/>,
        'Disclosure (View-Only)': <Disclosure tenant={Tenant}/>,
        'Media (View-Only)': <Media tenant={Tenant}/>,
        'Integrations': <Integrations tenant={Tenant}/>,
        'API Access': <APIAccess tenant={Tenant}/>,
        'Archive Audit': <ArchiveAudit tenant={Tenant}/>,
        'Archive Layout': <ArchiveLayout tenant={Tenant}/>,
        'Archive Provider': <ConnectArchiver tenant={Tenant}/>,
        'Delete Consent': <DeleteConsent tenant={Tenant}/>,
        'System Admin Tools': <SystemAdminTools tenant={Tenant}/>
    }

    return (
        <Wrapper>
            <SideBar>
                <View style={{flexDirection:'row',alignItems:'center',borderBottomWidth:1,borderBottomColor:'rgba(0,0,0,.25)'}}>
                    <Icon style={{padding:10}} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24}/>
                <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{Tenant.data.tenant.name}</Text>
                </View>
                <Accordion
                    data={accountSettings}
                />
            </SideBar>
            <Content>
            {
                screen &&
                <View style={{flex:1}}>

                    <Text style={{fontFamily:AppFonts.Roboto_Bold,marginBottom:0,borderBottomColor:'rgba(0,0,0,.25)',borderBottomWidth:1,paddingBottom:10,paddingTop:10,paddingLeft:10}}>{screen}</Text>
                    <View style={{flex:1,padding:10}}>
                    {KEY_COMPONENT[screen]}
                    </View>
                </View>
             }

            </Content>
        </Wrapper>
    )
}

export default AccountDetails;